import React from "react";
import { Routes, Route } from 'react-router-dom';
import Duties from "./pages/Duites";
import Election from "./pages/Election";
import Gym from "./pages/Gym";
import Home from "./pages/Home";
import Law from "./pages/Law";
import Parking from "./pages/Parking";
import Pet from "./pages/Pet";
import Society from "./pages/Society";
import Swimming from "./pages/Swimming";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Verify from "./pages/Verify";
import Pending from "./pages/Pending";
import Dashboard from "./users/Dashboard";
import Uprofile from "./users/Uprofile";
import Uevent from "./users/Uevent";
import Urules from "./users/Urules";
import Ucom from "./users/Ucom";
import Uinbox from "./users/Uinbox";
import Ucard from "./users/Ucard";
import Urfid from "./users/Urfid";
import Uparking from "./users/Uparking";
import Upet from "./users/Upet";
import Uhall from "./users/Uhall";
import Ustaff from "./users/Ustaff";
import Uvoting from "./users/Uvoting";
import Offer from "./pages/Offer";
import ForgotPwd from "./pages/ForgotPwd";
import Forgototp from "./pages/Forgototp";
import Uexit from "./users/Uexit";
import Unav from "./components/Unav";
import Updatepwd from "./users/Updatepwd";
import Uhome from "./users/Uhome";
import Ulaw from "./Upages/Ulaw";
import Ucparking from "./Upages/Ucparking";
import Uelection from "./Upages/Uelection";
import Uswimming from "./Upages/Uswimming";
import Usociety from "./Upages/Usociety";
import Ugym from "./Upages/Ugym";
import Bill from "./users/Bill";
import Uoffer from "./Upages/Uoffer";
import NotFoundPage from "./pages/NotFoundPage";
import Uduties from "./Upages/Uduites";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/duties-of-residents" element={<Duties />} /> 
        <Route path="/home/duties-of-residents" element={<Uduties />} /> 
        <Route path="/election-procedure-for-housing-societies" element={<Election />} />
        <Route path="/home/election-procedure-for-housing-societies" element={<Uelection />} />
        <Route path="/reserve-parking-rules" element={<Parking />} />
        <Route path="/home/reserve-parking-rules" element={<Ucparking />} />
        <Route path="/apartments-pet-rules" element={<Pet />} />
        <Route path="/home/apartments-pet-rules" element={<Upet />} />
        <Route path="/housing-society-rules&regulations-for-tenants" element={<Society />} />
        <Route path="/home/housing-society-rules&regulations-for-tenants" element={<Usociety />} />
        <Route path="/swimming-pool-rules&regulations" element={<Swimming />} />
        <Route path="/home/swimming-pool-rules&regulations" element={<Uswimming />} />
        <Route path="/gym-rules&regulations" element={<Gym />} />
        <Route path="/home/gym-rules&regulations" element={<Ugym />} />
        <Route path="/society-bylaws-for-internal-repairs" element={<Law />} />
        <Route path="/home/society-bylaws-for-internal-repairs" element={<Ulaw />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/pending" element={<Pending />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/myprofile" element={<Uprofile />} />
        <Route path="/event" element={<Uevent />} />
        <Route path="/rules" element={<Urules />} />
        <Route path="/communication-update" element={<Ucom />} />
        <Route path="/inbox" element={<Uinbox />} />
        <Route path="/access-card" element={<Ucard />} />
        <Route path="/rfid-tag" element={<Urfid />} />
        <Route path="/parking-sticker" element={<Uparking />} />
        <Route path="/pet-register" element={<Upet />} />
        <Route path="/booking-society-area" element={<Uhall />} />
        <Route path="/staff" element={<Ustaff />} />
        <Route path="/voting" element={<Uvoting />} />
        <Route path="/offer" element={<Offer />} />
        <Route path="/forgot-pwd" element={<ForgotPwd />} />
        <Route path="/forgot-password" element={<Forgototp />} />
        <Route path="/exit" element={<Uexit />} />
        <Route path="/unav" element={<Unav />} />
        <Route path="/change-password" element={<Updatepwd />} />
        <Route path="/home" element={<Uhome />} />
        <Route path="/bill" element={<Bill />} />
        <Route path="/home/offer" element={<Uoffer />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
