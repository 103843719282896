
import React from "react";


const Modelform = () =>{ 
    return(
        <>
        


        <div className="model">
          {/* modelpopup hospital */}
          <div className="modal fade" id="exampleMod" tabIndex={-1} aria-labelledby="exampleMod" aria-hidden="true">
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header bg-info">
                  <h4 className="modal-title radiant" id="exampleMod">Hospital</h4>
                  <button type="button" className="btn btn-outline-danger" id="btn4" data-bs-dismiss="modal" aria-label="Close"> <b>X</b></button>
                </div>
                <div className="model-body">
                  <ul className="mt-3 mb-4">
                    <a className="dropdown-item" id="ah" href="https://www.medanta.org/" target="_blank" rel="noopener noreferrer">
                      1. Medanta-The Medicity </a>
                    <a className="dropdown-item" id="ah" href>
                      2. Fortis Memorial Research Institute </a>
                    <a className="dropdown-item" id="ah" href >
                      3. Artemis Hospital</a>
                    <a className="dropdown-item" id="ah" href >
                      4.  Park Hospital</a>
                    <a className="dropdown-item" id="ah" href >
                      5.  Paras Hospitals </a>
                    <a className="dropdown-item" id="ah" href >
                      6. CK Birla Hospital</a>
                    <a className="dropdown-item" id="ah" href >
                      7. Max Hospital</a>
                    <a className="dropdown-item" id="ah" href >
                      8. Narayana Superspeciality Hospital</a>
                    <a className="dropdown-item" id="ah" href >
                      9.Manipal Hospital, Palam Vihar</a>
                    <a className="dropdown-item" id="ah" href >
                      10. Marengo Asia Hospitals</a>

                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleMo" tabIndex={-1} aria-labelledby="exampleMo" aria-hidden="true">
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header bg-info">
                  <h4 className="modal-title radiant" id="exampleMod">Educational Institute</h4>
                  <button type="button" className="btn btn-outline-danger" id="btn4" data-bs-dismiss="modal" aria-label="Close"> <b>X</b></button>
                </div>
                <div className="model-body">
                  <ul className="mt-3 mb-4">
                    <a className="dropdown-item" id="ah" href="https://www.bmu.edu.in/" target="_blank" rel="noopener noreferrer" >
                      1. BML Munjal University
                    </a>
                    <a className="dropdown-item" id="ah" href>
                      2.  GD Goenka University  </a>
                    <a className="dropdown-item" id="ah" href >
                      3. Alpine Convent School
                    </a>
                    <a className="dropdown-item" id="ah" href >
                      4. Blue Bells Model School</a>
                    <a className="dropdown-item" id="ah" href >
                      5. Lancers International School
                    </a>
                    <a className="dropdown-item" id="ah" href >
                      6. Scottish High International School</a>
                    <a className="dropdown-item" id="ah" href >
                      7. Great Lakes Institute of Management (GLIM)</a>
                    <a className="dropdown-item" id="ah" href >
                      8. Gurgoan Public School</a>
                    <a className="dropdown-item" id="ah" href >
                      9. Pathways School, Baliawas</a>
                    <a className="dropdown-item" id="ah" href >
                      10. Management Development Institute (MDI)</a>

                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="Transportation" tabIndex={-1} aria-labelledby="Transportation" aria-hidden="true">
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header bg-info">
                  <h4 className="modal-title radiant" id="Transportation">Transportation Hub</h4>
                  <button type="button" className="btn btn-outline-danger" id="btn4" data-bs-dismiss="modal" aria-label="Close"> <b>X</b></button>
                </div>
                <div className="model-body">
                  <ul className="mt-3 mb-4">
                    <a className="dropdown-item" id="ah" href="https://www.delhimetrorail.com/line/LN2" target="_blank" rel="noopener noreferrer"  >
                      1. Huda City Centre Gurgoan
                    </a>
                    <a className="dropdown-item" id="ah" href>
                      2. Gurgoan Bus Stand </a>
                    <a className="dropdown-item" id="ah" href >
                      3.    Hero Honda Chowk
                    </a>
                    <a className="dropdown-item" id="ah" href >
                      4.  	Huda City Centre Metro Station</a>
                    <a className="dropdown-item" id="ah" href >
                      5.
                      Business Zone Sector 50

                    </a>
                    <a className="dropdown-item" id="ah" href >
                      6.  Gurgoan Railway Station	</a>
                    <a className="dropdown-item" id="ah" href >
                      7. Sohna Bus Stand </a>
                    <a className="dropdown-item" id="ah" href >
                      8.  M.G Road Metro Station </a>
                    <a className="dropdown-item" id="ah" href >
                      9.  Dundahera	Hero Honda Chowk  </a>
                    <a className="dropdown-item" id="ah" href >
                      10.    Police Line Manesar </a>

                  </ul>
                </div>

              </div>
            </div>
          </div>
          <div className="modal fade" id="examplesh" tabIndex={-1} aria-labelledby="examplesh" aria-hidden="true">
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header bg-info">
                  <h4 className="modal-title radiant" id="examplesh">Shopping Centre</h4>
                  <button type="button" className="btn btn-outline-danger" id="btn4" data-bs-dismiss="modal" aria-label="Close"> <b>X</b></button>
                </div>
                <div className="model-body">
                  <ul className="mt-3 mb-4">
                    <a className="dropdown-item" id="ah" href="https://www.dlfcyberhub.com/" target="_blank" rel="noopener noreferrer" >
                      1. DLF Cyber Hub
                    </a>
                    <a className="dropdown-item" id="ah" href>
                      2. Ambience Mall
                    </a>
                    <a className="dropdown-item" id="ah" href >
                      3. Galleria Market. Shopping Malls
                    </a>
                    <a className="dropdown-item" id="ah" href >
                      4. MGF Metropolitan Mall</a>
                    <a className="dropdown-item" id="ah" href >
                      5. Sahara Mall
                    </a>
                    <a className="dropdown-item" id="ah" href >
                      6.  Gurgaon Central Mall	</a>
                    <a className="dropdown-item" id="ah" href >
                      7. Hong Kong Bazaar</a>
                    <a className="dropdown-item" id="ah" href >
                      8. Gold Souk Mall</a>
                    <a className="dropdown-item" id="ah" href >
                      9. Good Earth City Center </a>
                    <a className="dropdown-item" id="ah" href >
                      10. Worldmark Gurgaon </a>

                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleco" tabIndex={-1} aria-labelledby="exampleco" aria-hidden="true">
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header bg-info">
                  <h4 className="modal-title radiant" id="exampleco">Commute</h4>
                  <button type="button" className="btn btn-outline-danger" id="btn4" data-bs-dismiss="modal" aria-label="Close"> <b>X</b></button>
                </div>
                <div className="model-body">
                  <ul className="mt-3 mb-4">
                    <a className="dropdown-item" id="ah" href="https://en.wikipedia.org/wiki/Kingdom_of_Dreams" target="_blank" rel="noopener noreferrer" >
                      1. Kingdom of Dreams
                    </a>
                    <a className="dropdown-item" id="ah" href>
                      2. Cyber Hub
                    </a>
                    <a className="dropdown-item" id="ah" href >
                      3. Ambience Mall
                    </a>
                    <a className="dropdown-item" id="ah" href >
                      4. Damdama Lake </a>
                    <a className="dropdown-item" id="ah" href >
                      5. Sultanpur Bird Sanctuary
                    </a>
                    <a className="dropdown-item" id="ah" href >
                      6.   Appu Ghar	</a>
                    <a className="dropdown-item" id="ah" href >
                      7. Manesar</a>
                    <a className="dropdown-item" id="ah" href >
                      8. Heritage Transport Museum </a>
                    <a className="dropdown-item" id="ah" href >
                      9.  MGF Mall  </a>
                    <a className="dropdown-item" id="ah" href >
                      10. Leisure Valley Park </a>

                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="Restaurants" tabIndex={-1} aria-labelledby="Restaurants" aria-hidden="true">
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header bg-info">
                  <h4 className="modal-title radiant" id="Restaurants">Restaurants</h4>
                  <button type="button" className="btn btn-outline-danger" id="btn4" data-bs-dismiss="modal" aria-label="Close"> <b>X</b></button>
                </div>
                <div className="model-body">
                  <ul className="mt-3 mb-4">
                    <a className="dropdown-item" id="ah" href="https://www.pregowestingurgaon.com/" target="_blank" rel="noopener noreferrer" >
                      1. Prego Restaurant, Gurgaon
                    </a>
                    <a className="dropdown-item" id="ah" href>
                      2. 21 Gun Salute Restaurant, Gurgaon
                    </a>
                    <a className="dropdown-item" id="ah" href >
                      3. Gung the Palace Restaurant, Gurgaon
                    </a>
                    <a className="dropdown-item" id="ah" href >
                      4. Swagath Restaurant, Gurgaon </a>
                    <a className="dropdown-item" id="ah" href >
                      5. Jungle Jamboree Restaurant, Gurgaon
                    </a>
                    <a className="dropdown-item" id="ah" href >
                      6.  Farzi Café Restaurant, Gurgaon</a>
                    <a className="dropdown-item" id="ah" href >
                      7. SodaBottleOpenerWala Restaurant, Gurgaon </a>
                    <a className="dropdown-item" id="ah" href >
                      8. Dhaba- Estd 1986 Delhi, Restaurant, Gurgaon </a>
                    <a className="dropdown-item" id="ah" href >
                      9. Burma Burma Restaurant, Gurgaon </a>
                    <a className="dropdown-item" id="ah" href >
                      10. Amaranta Restaurant, Gurgaon </a>

                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
        
        </>
    )
}

export default Modelform;