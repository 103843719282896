import React from "react";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
import Cduties from "./Cduties";


const Uduties = () => {

    return(
        <>
            <Unavbar />
            <Cduties />
            <Footer/>
    </>
    );
}
export default Uduties;
