import React from "react";


const Cpet = () => {

    return(
        <>
             <section className="hero-section" id="section_1">
          <div className="section-overlay" />
          <div className="video-wrap">
            <img src="assets/images/pet ruls.jpg" alt="" className="custom-video" id="imgym" />
          </div>
        </section>
        <section className="about-section">
          <div className="container">
            <h2 className="radiant mt-2">Apartments Pet Rules – Rights &amp; Responsibility of Pet Owners in Housing
              Societies</h2>
            <hr />
          </div>
        </section>
        <section>
          <div className="container">
            <p style={{textAlign: 'justify', fontWeight: 500}}>Animal lovers know too well the challenges of keeping a
              dog/pet in a housing society. To ensure that community living is not hampered in any way, there are
              certain
              pet rules in a housing society that residents must follow. To start off, the Animal Welfare Board of
              India
              has
              passed certain dog laws to ensure our furry companions are not harassed or treated unfairly. Both
              pet owners
              and the management must be aware of laws for pet owners living in apartments. These dog laws and
              awareness
              about
              pet rights help to keep the housing society functions smoothly. This
              article takes an in-depth look at the pet rules in housing society in India.</p> <br />
            <h4 style={{textAlign: 'justify', fontWeight: 500}}>Pet laws in India for
              pet
              owners living in apartments</h4><br />
            <h6 style={{textAlign: 'justify', fontWeight: 500}}>1. Ban of
              pets is
              illegal </h6>
            <p style={{textAlign: 'justify'}} className="my-4">The advisory is being issued in pursuance
              of<b>
                ‘Section 9(k)
                Prevention of Cruelty to Animals Act,1960’, it is illegal for housing societies to pass pet</b>
              bye laws
              that
              disallow pets, not even on a majority vote by the residents of the society. According to <b>article
                51 A
                (g), it is the duty of
                every citizen to have compassion for animals</b> and any living creature.</p>
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">2. No
              discrimination allowed</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>A housing society cannot ban pets/dogs on
              the
              basis of their breed or size. Even complaints regarding
              a dog’s barking habits cannot be used to ban the pet. </p>
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">3. Use of common facilities</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>A housing society cannot ban a pet from
              using
              common facilities like lifts and parks or impose a fine or special charge for the same. </p>
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">4. Cruelty is punishable</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>ociety cannot create dog harassment laws.
              Any
              cruelty towards strays is illegal under the ‘Prevention of Cruelty
              to Animals Act. It is also a punishable offence under Section 428 and 429 of the Indian penal code.
            </p>
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">5. No ban on feeding</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>No resident can be barred from feeding
              stray
              dogs/cats in the locality. </p>
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">6. Protected animals</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>Certain animals like bears, monkeys,
              and parakeets cannot be trained or used for entertainment under the ‘Prevention of Cruelty to
              Animals
              Act’. Most rodents and reptiles are also prohibited under this act. </p><br />
            <h4 style={{color: 'var(--e-global-color-vamtam_accent_6)'}}>Role of the management committee </h4><br />
            <p className="nmn my-2" style={{textAlign: 'justify'}}>In view of these bye-laws for pets,
              society must
              take
              care not to violate rules for pets in society. Society can however still create and enforce rules
              and
              regulations of keeping dogs to ensure the welfare of the pets and the residents. The following pet
              guidelines
              can be implemented by society to maintain peace. </p> <br />
            <h4 style={{color: 'var(--e-global-color-vamtam_accent_6)'}}>Pet rules in housing society </h4><br />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">1. Create time slots</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>Create time slots when the pets can
              access
              gardens, parks, and grounds.</p>
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">2. Keep vaccination records</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>The management committee can ask for vaccination and
              sterilization records in case issues arise in the future. </p>
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">3.
              Designate
              defecation spots</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>Cordon of areas outside the community premises where
              the pet
              owners can take their pets. Furthermore, society can advise pet owners to clean up after their dogs
              inside
              society as a courtesy</p>
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">4. Always accompanied by owners
            </h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>When out of the house and in the society premises, the
              apartment association should make sure the pet is accompanied by the owner. </p>
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">5. Designate feeding spots</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>If the residents wish to feed strays, earmark feeding
              spots
              that are not close to the residential space or children’s play areas. </p>
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">6. Organise and educate</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>Educate the residents on animals, especially strays.
              The
              management committee can also tie-up with Animal Welfare Organisations to take care of the
              vaccination and
              sterilization needs of strays. The strays must be released back into
              the same locality once the vaccination is administered.</p> <br />
            <h4 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}}>Responsibility of pet
              owners-
              rules and regulations of keeping a dog in apartment </h4><br />
            <p className="nmn my-2" style={{textAlign: 'justify'}}>responsibilities of pet owner is a housing society
              A responsible pet owner is crucial to peaceful community living. While it is important for the pet
              owner to
              be aware of the dog laws that keep them safe, they should also be aware of their responsibilities of
              keeping
              a pet as a resident in a community. These suggestions should be kept in mind when using
              common facilities. The following are the rules for dog owners in India.</p> <br />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">1. Train your pet</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>Ensure you give your pet ample obedience
              training, so the dog does behave untowardly
              in common areas or gets provoked when amidst other residents. </p> <br />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">2. Familiarize your pet</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>Society is home to many unfamiliar faces. Make sure
              your pet
              knows the
              surroundings he/she resides in and does not harm a stranger or cause a nuisance.</p> <br />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">3. Vaccinate on time</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>All vaccinations must be administered on a timely
              basis.
              For cats, Anti- Rabies and Feligen/TriCat must be renewed every year. Dogs must be vaccinated with
              Anti-Rabies, DHLPPi, and KC vaccines every year. Medications must be administered in case your
              pet is suffering from any illness. When your housing society organises vaccination or sterilisation
              drives
              for strays,
              participate and encourage other pet owners to actively take part in the proceedings. </p> <br />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">4. Follow the rules of society
            </h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>Before renting or buying a place, make it a point to
              go
              through
              society by-laws and advisories. Adhere to them to avoid hassling other residents. If your society
              has
              dedicated time slots for
              your pets to access parks and gardens, make sure you stick to them.</p> <br />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">5. Clean up after</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>The main concern that most people have is cleanliness,
              so make sure your pet is toilet trained. It is advisable for pet owners to clean up after their
              furry pal,
              if he/she has an accident inside the society premises, even if it is not required by law. It is also
              advisable
              to maintain the hygiene of your pet for their own well-being and so no one is inconvenienced. In
              case, you
              feed strays make
              sure there are no leftovers once the strays have had their fill.</p> <br />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">6. Supervise your pet</h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>Even if your pet is well-trained, it is necessary to
              supervise your pet when in common spaces. Keep your pets on a leash when you take them out for a
              walk
              or when they are playing around children to ensure safety. Ensure your pet is not aggressive or
              hostile
              towards
              strays or other animals in the vicinity. </p> <br />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)'}} className="my-3">7. Be a responsible pet owner
            </h6>
            <p className="nmn my-2" style={{textAlign: 'justify'}}>Make sure your pets get ample exercise, so they are
              not
              excessively
              energetic. If your pet is alone for long hours, make sure she/he has ample food, water, and toys.
              Make sure
              they do not create
              a nuisance in your absence. </p> <br />
            <h4 style={{color: 'var(--e-global-color-vamtam_accent_6)'}}>What laws govern stray dogs in India? </h4>
            <br />
            <p className="nmn my-2" style={{textAlign: 'justify'}}> The Delhi High Court (HC) has emphasised the need to
              create
              ‘guard and dog partnerships’ in consultation with the Police to train stray dogs to protect the
              housing
              society and not harm its residents as a part of the rules for stray dogs in society. This would be
              done in
              order to make sure that residents are safe from any potential harm that could come from these stray
              dogs.
            </p> <br />
            <p className="nmn my-2" style={{textAlign: 'justify'}}>The court has issued directives to citizens,
              Resident Welfare Associations (AOA) and municipal bodies regarding the feeding of stray dogs. The
              court
              observed that stray dogs must have access to food and water if there are no community dog feeders in
              the
              area. It also stated that the Animal Welfare Board of India (AWBI) and AOA or the municipality
              should work
              closely to create designated feeding spots where humans don’t frequent. This would give the dogs a
              sense of security as they are territorial in nature. </p> <br />
            <p className="nmn my-2" style={{textAlign: 'justify'}}> In addition, the Prevention of Cruelty to Animals Act
              of
              1960,
              particularly Section 38, provides protection for stray dogs. Dogs cannot be relocated or ejected
              from their
              domain in accordance with the Animal Birth Control (Dogs) Rules, 2001. Additionally, the Central
              Government
              issued an Office Memorandum in 2006 urging employees to refrain
              from abusing stray dogs.</p> <br />
            <p className="nmn my-2" style={{textAlign: 'justify'}}> However, the Delhi High Court’s judgement was briefly
              overturned by
              the Supreme Court in March 2022, largely due to the fact that stray dogs can be fed in housing
              communities.
              However,
              the ban was lifted in May 2022 with the justification that no citizen could be prohibited from
              providing
              stray dogs
              with food or water.</p> <br />
            <h4 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}}>What is the role of an
              AOA in
              maintaining a gated society with stray dogs?</h4><br />
            <p className="nmn my-2" style={{textAlign: 'justify'}}>AOA are typically responsible for maintaining the
              housing
              society. However,
              they may often be unaware of how to control stray dogs inside the area. This can be due to the fact
              that
              many residents feed them,
              which ends up increasing the stray dog population within the
              gated community. </p> <br />
            <p className="nmn my-2" style={{textAlign: 'justify'}}>With the approval of the order from HC, AOA now need
              to focus
              on
              increasing awareness on the subject. AOA can work on better implementation of Animal Birth Control
              (ABC) to
              drive
              sterilisation with support from AWBI. Also, the latest draft of ABC for 2022 talks about vaccination
              and the
              formation of stray monitoring committees within society. Once passed, AOA will need to undertake
              activities
              to ensure the peaceful co-existence of stray dogs
              and humans.</p> <br />
            <p className="nmn my-2" style={{textAlign: 'justify'}}>Our animal companions are an inseparable part of our
              lives.
              With a
              cooperative attitude and thoughtful policies on the part of pet owners, other residents, and
              management
              committees, we
              can ensure the welfare of furry friends and the
              safety of society.</p> <br /><br />
            <p style={{textAlign: 'justify'}}><b><span>Types of
                  Co-operative
                  Societies in India,
                  Special General Body Meetings in a Housing Society,
                  Rights of Associate Members in a Cooperative Housing Society</span></b></p><br /><br />
          </div>
        </section>
    </>
    );
}
export default Cpet;
