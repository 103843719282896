import React from "react";
import { Link } from "react-router-dom";
import Modelform from "./Modelform";

const Navbar = () =>{ 
    return(
        <>
        
         <nav className="navbar navbar-expand-lg fixed-top" style={{ backgroundColor:  '#f6f6f9'}} >
            <div className="container" id="nav">
              <Link to="/" className="navbar-brand">
                <span className="topleft"><img src="assets/images/logo1re.png" alt="" className="logoname img-fluid" /></span>
              </Link>
              <Link to="/login"  className="btn custom-btn d-lg-none ms-auto me-2"><span id="#btn2">Login</span></Link>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon text-center" />
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav align-items-lg-center" id="navv">
                  <li className="nav-item">
                    <Link to="/" className="nav-link click-scroll"><i className="bi bi-house" />
                      <br />Home</Link>
                  </li>
                  <li className="nav-item dropdown">
                    <li className="nav-link dropdown-toggle "  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="bi bi-send-check-fill" /> <br /> Offers &amp; Discount
                    </li>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li id="linav">
                      <Link className="dropdown-item mt-1" data-bs-toggle="modal" data-bs-target="#exampleMod" >
                      <div className="row">
                        <div className="col-6">
                        Hospital »
                        </div>
                        <div className="col-6 text-end">
                        <span className="text-danger" style={{fontSize: '15px'}}>See All</span>
                          </div>
                      </div>
                       </Link>
                       
                      </li>
                      <hr />
                    
                      <li id="linav">
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#Restaurants" >
                      <div className="row">
                        <div className="col-6">
                        Restaurants »
                        </div>
                        <div className="col-6 text-end">
                        <span className="text-danger" style={{fontSize: '15px'}}>See All</span>
                          </div>
                      </div>
                       </Link>

                      </li>
                      <hr />
                    
                      <li id="linav">
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleMo" >
                      <div className="row">
                        <div className="col-6">
                        Educational Institute »
                        </div>
                        <div className="col-6 text-end">
                        <span className="text-danger" style={{fontSize: '15px'}}>See All</span>
                          </div>
                      </div>
                       </Link>

                      </li>
                      <hr />
                      <li id="linav">
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#examplesh" >
                      <div className="row">
                        <div className="col-6">
                        Shopping Centre »
                        </div>
                        <div className="col-6 text-end">
                        <span className="text-danger" style={{fontSize: '15px'}}>See All</span>
                          </div>
                      </div>
                       </Link>

                      </li>
                      <hr />
                      <li id="linav">
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#examplesh" >
                      <div className="row">
                        <div className="col-6">
                        Jewellery Showrooms »
                        </div>
                        <div className="col-6 text-end">
                        <span className="text-danger" style={{fontSize: '15px'}}>See All</span>
                          </div>
                      </div>
                       </Link>

                      </li>
                      <hr />
              
                      <li id="linav">
                        <a className="dropdown-item" href="/#commercial"> New Commercial Development »
                        </a>
                      </li>
                      <hr />
                      <li id="linav">
                        <a className="dropdown-item mb-3" href="/#residential"> New Residential Development »
                        </a>
                      </li> 
                    </ul>
          
                  </li>
                  <li className="nav-item">
                    <a className="nav-link " href="https://www.ratnkunj.com/reality/post_property" target="_blank" rel="noopener noreferrer" ><i className="bi bi-basket2-fill" /><br /> Sell or Rent</a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/"><i className="bi bi-chat-left-text" /> <br />
                      Messages</Link>
                  </li>
                  <li className="nav-item ">
                    <Link className="nav-link" to="/login"><i className="bi bi-person-circle" /> <br />
                      Login</Link>
                  </li>
                </ul>
              </div>
            </div>
         </nav>

         <Modelform />
        
        </>
    )
}

export default Navbar;