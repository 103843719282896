import React from "react"
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import { Helmet } from 'react-helmet';
const ForgotPwd = () => {
  return (

    <>
       <Helmet>
        <title>OTP Verification at  ROF Aalayas</title>
        <meta name="title" property="og:title" content="OTP Verification at  ROF Aalayas" />
        <meta name="description" content="Learn about the OTP verification process at  ROF Aalayas, ensuring secure access to resident accounts and services." />
        <meta property="og:description" content="Learn about the OTP verification process at  ROF Aalayas, ensuring secure access to resident accounts and services." />
      </Helmet>
      <style dangerouslySetInnerHTML={{ __html: "body{background: rgb(63,94,251); background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);\n        }\n        .navbar {background-color: #babebf;} .hero-section{height: auto;}" }} />

      <Navbar />

      <div className="container hero-section">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-12">
            <div className="box" style={{ backgroundColor: 'rgb(222, 243, 209)' }}>
        
              <div className="row justify-content-center">
              <div className="col-11 mt-3">  
                  <p className="text-danger mt-2" >An OTP is being sent to your phone via text message. Please wait a minute for it to be
                    delivered before requesting a resend.</p>
                </div>
                <div className="col-lg-8 col-10">
                  <label htmlFor="Password" className="w-100">Enter OTP</label><br />
                  <input type="password" placeholder="Enter OTP" className="form-control  bg-light p-2 mt-2 w-100" />
                  <hr />
                  <label htmlFor="Password" className="w-100">Enter New Password</label><br />
                  <input type="password" placeholder="Password" className="form-control  bg-light p-2 mt-2 w-100" />
                  <label htmlFor="Password" className="w-100 mt-4">Confirm New Password</label><br />
                  <input type="password" placeholder="Re-enter Password" className="form-control  bg-light p-2 mt-2 w-100" />
                </div>
              </div>
              <div className="row p-5 justify-content-center">
                <div className="col-lg-6 col-12"> <a href className="btn btn-primary w-100 mb-4">Reset Password</a>
                </div>
                <div className="col-lg-6 col-12"><Link to="/login" className="btn btn-primary w-100  ">Back to Login</Link></div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  )
}

export default ForgotPwd;
